import { Box } from '@mui/material';
import _, { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import RefreshIcon from '#/assets/Refresh.svg';

import { useCampaignInvitationsStyles } from '#/components/campaignInvitations/CampaignInvitations.styles';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import CommunicationsUserCompany from '#/components/features/communications/filters/CommunicationsUserCompany/CommunicationsUserCompany';
import ProductMixSelect from '#/components/shared/ProductMixSelect/ProductMixSelect';

import { useAppSelector } from '#/hooks';

import { usePostCommunicationsRecipientsMutation } from '#/store/api/communications/communications';
import { communicationsPromote } from '#/store/slices';

export interface ICommunicationsFilterRecipientState {
  userNames: string;
  userEmails: string;
  agencyIds: string[];
  parentCompanies: string;
  invitationStatus: string;
  additionalCategoryIds: string[];
  additionalSubCategoryIds: string[];
  additionalAttributeIds: string[];
}

const initialState = {
  userNames: '',
  userEmails: '',
  agencyIds: [],
  parentCompanies: '',
  invitationStatus: '',
  additionalCategoryIds: [],
  additionalSubCategoryIds: [],
  additionalAttributeIds: [],
};

const CommunicationsFilterRecipients = () => {
  const classes = useCampaignInvitationsStyles();
  const [recipientsFilters, setRecipientsFilters] = useState(initialState as ICommunicationsFilterRecipientState);

  const handleChange = (value: string | string[] | boolean, updateFilterName: string) =>
    setRecipientsFilters({ ...recipientsFilters, [updateFilterName]: value });

  const [getCommunicationAllCompanies] = usePostCommunicationsRecipientsMutation({
    fixedCacheKey: 'communications-recipients',
  });
  const { selectedCampaigns } = useAppSelector((state) => state.communications);
  const selectedBrandIds = useAppSelector((state) => communicationsPromote.getSelectedBrandsIds(state.communications));

  useEffect(() => {
    const debounceFn = _.debounce(
      () =>
        getCommunicationAllCompanies({
          campaignIds: selectedCampaigns,
          companyIds: selectedBrandIds,
          filters: recipientsFilters,
        }),
      600,
    );
    debounceFn();
    return () => debounceFn.cancel();
  }, [recipientsFilters]);

  return (
    <>
      <Box className={classes.flexWithSpace}>
        <TypographyWithFontFamily className={classes.mb05} variant="H5_Base">
          Filters
        </TypographyWithFontFamily>
        {!isEqual(recipientsFilters, initialState) && (
          <TypographyWithFontFamily className={classes.clearAll} onClick={() => setRecipientsFilters(initialState)}>
            <img alt="refresh icon" className={classes.refreshIcon} src={RefreshIcon} />
            Clear all
          </TypographyWithFontFamily>
        )}
      </Box>
      <ProductMixSelect
        additionalAttributeIds={recipientsFilters.additionalAttributeIds}
        additionalCategoryIds={recipientsFilters.additionalCategoryIds}
        additionalSubCategoryIds={recipientsFilters.additionalSubCategoryIds}
        handleChange={handleChange}
      />
      <CommunicationsUserCompany
        handleChange={handleChange}
        parentCompanies={recipientsFilters.parentCompanies}
        userEmails={recipientsFilters.userEmails}
        userNames={recipientsFilters.userNames}
      />
    </>
  );
};
export default CommunicationsFilterRecipients;
