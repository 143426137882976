import ReportsIcon from '../../../../assets/BlueReportsIcon.svg';
import DownloadIconBlue from '../../../../assets/DownloadIconBlue.svg';
import EyeIcon from '../../../../assets/EyeIcon.svg';
import { IQuickLinks } from '../../../../interfaces/dashboard/quickLinks';

export const retailerLinks: IQuickLinks[] = [
  {
    name: 'Turbyne Video Guide',
    url: 'https://player.vimeo.com/video/1009805758?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    icon: EyeIcon,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing Playbook',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/retailer-account/static/51-Sprouts%20Shopper%20Marketing%20-%202025%20Playbook%202.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing Campaign Brief',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/retailer-account/static/78-Sprouts-Custom-Program-Brief-2023.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing FAQs',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/retailer-account/static/79-FAQs%20-%20Shopper%20Marketing.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: ReportsIcon,
  },
];

export const brandLinks: IQuickLinks[] = [
  {
    name: 'Turbyne Video Guide',
    url: 'https://player.vimeo.com/video/1009805758?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    icon: EyeIcon,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing Playbook',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/retailer-account/static/51-Sprouts%20Shopper%20Marketing%20-%202025%20Playbook%202.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing Campaign Brief',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/retailer-account/static/78-Sprouts-Custom-Program-Brief-2023.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Shopper Marketing FAQs',
    url: 'https://turbdemosfiles.blob.core.windows.net/turbyne-retailer/retailer-account/static/79-FAQs%20-%20Shopper%20Marketing.pdf',
    icon: DownloadIconBlue,
    rel: 'noopener noreferrer',
    target: '_blank',
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: ReportsIcon,
  },
];
