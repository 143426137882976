import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useBrandUserFormStyles = makeStyles((theme) =>
  createStyles({
    card: {
      '&&': {
        marginBottom: '0px',
      },
      height: '100vh',
    },
    wrapper: {
      width: '75%',
      margin: '0 auto',
      padding: '50px 0',
    },
    title: {
      '&&': {
        textAlign: 'center',
        fontSize: '2rem',
        marginTop: '0.625rem',
        fontWeight: THEME.FONT_WEIGHTS.SEMIBOLD,
        color: THEME.PALETTES.TEXT.Action_Primary,
        marginBottom: '1.875rem',
      },
    },
    mb25: {
      marginBottom: '25px',
    },
    items: {
      marginTop: '30px',
      display: 'flex',
    },
    firstDiv: {
      width: '48%',
      marginRight: '4%',
    },
    secondDiv: {
      width: '48%',
    },
    label: {
      '&&': {
        fontWeight: THEME.FONT_WEIGHTS.MEDIUM,
        color: THEME.PALETTES.TEXT.Gray,
        marginBottom: '0.313rem',
        fontSize: '0.75rem',
      },
    },
    red: {
      '&&': {
        color: 'red',
      },
    },
    textArea: {
      width: '100%',
      marginTop: '0.625rem',
      borderRadius: '8px',
      padding: '0.938rem',
      borderColor: THEME.PALETTES.BORDER.Light,
    },
    bottomDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '50px',
    },
    successMessage: {
      color: 'green',
      textAlign: 'center',
      marginTop: '30px',
    },
    buttonsContainer: {
      display: 'flex',
      columnGap: theme.custom.REM_SPACES['1'],
    },
    logo: {
      width: '250px'
    },
    logoContainer: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex'
    }
  }),
);
