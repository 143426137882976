import { Box, FormLabel, TextareaAutosize } from '@mui/material';
import { useNavigate } from 'react-router';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import TextField from '#/components/common/inputs/TextField/TextField';

import Logo from '../../../../assets/TurbyneLogo.svg';
import SproutLogo from '../../../../assets/SproutsLogo.png';
import { useCreateBrandUser } from '../../../../hooks/forms/users/useCreateBrandUser';
import NewButton from '../../../common/button/NewButton';
import Card from '../../../common/card/card';
import Loader from '../../../common/loader/loader';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useBrandUserFormStyles } from './brandUserForm.styles';

const CreateBrandUserForm = () => {
  const { onSubmit, loading, register, watch, setValue, successfulCreated, error, errors } = useCreateBrandUser();
  const styles = useBrandUserFormStyles();
  const navigate = useNavigate();
  if (loading) {
    return <Loader />;
  }
  return (
    <Card customClass={styles.card}>
      <form onSubmit={onSubmit}>
        <Box className={styles.wrapper}>
          <Box>
            <Box className={styles.logoContainer}>
              <Box className={styles.logo}>
                <img alt="Sprout Logo" src={SproutLogo} />
              </Box>
            </Box>
            <TypographyWithFontFamily cName={styles.title} variant="H3_Base">
              Join the Sprouts Shopper Marketing Portal
            </TypographyWithFontFamily>
          </Box>
          <AlertError error={error} />
          <Box className={styles.items}>
            <Box className={styles.firstDiv}>
              <TextField
                error={errors.firstName?.message}
                label="FIRST NAME *"
                onChange={(e) => setValue('firstName', e.target.value)}
                value={watch('firstName')}
              />
            </Box>
            <Box className={styles.secondDiv}>
              <TextField
                error={errors.lastName?.message}
                label="LAST NAME *"
                onChange={(e) => setValue('lastName', e.target.value)}
                value={watch('lastName')}
              />
            </Box>
          </Box>
          <Box className={styles.items}>
            <Box className={styles.firstDiv}>
              <TextField
                error={errors.companyName?.message}
                label="COMPANY *"
                onChange={(e) => setValue('companyName', e.target.value)}
                value={watch('companyName')}
              />
            </Box>
            <Box className={styles.secondDiv}>
              <TextField
                error={errors.departmentName?.message}
                label="DEPARTMENT *"
                onChange={(e) => setValue('departmentName', e.target.value)}
                value={watch('departmentName')}
              />
            </Box>
          </Box>
          <Box className={styles.items}>
            <Box className={styles.firstDiv}>
              <TextField
                error={errors.title?.message}
                label="TITLE *"
                onChange={(e) => setValue('title', e.target.value)}
                value={watch('title')}
              />
            </Box>
            <Box className={styles.secondDiv}>
              <TextField
                error={errors.email?.message}
                label="WORK EMAIL *"
                onChange={(e) => setValue('email', e.target.value)}
                value={watch('email')}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <FormLabel className={styles.label} htmlFor="comments">
              COMMENTS - LIST THE BRANDS YOU REPRESENT *
            </FormLabel>
            <TextareaAutosize
              {...register('comments')}
              aria-multiline={true}
              className={styles.textArea}
              minLength={1}
              minRows={10}
            />
            {errors.comments && <span style={{ color: 'red' }}>{errors.comments.message}</span>}
          </Box>
          <Box className={styles.bottomDiv}>
            <Box className={styles.buttonsContainer}>
              <NewButton onClick={() => navigate('/login')} text="Back" variant="Secondary" />
              <NewButton text="Submit Request" type="submit" />
            </Box>
            <img alt="logo" src={Logo} />
          </Box>
          {successfulCreated && <Box className={styles.successMessage}>Successfully created</Box>}
        </Box>
      </form>
    </Card>
  );
};

export default CreateBrandUserForm;
