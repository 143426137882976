import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Card from '#/components/common/card/card';
import MultipleDownloadUpload from '#/components/common/downloadUpload/MultipleDownloadUpload';
import Loader from '#/components/common/loader/loader';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';
import { useGeneralSettingStyle } from '#/components/features/settings/dataAndCalendar/GeneralSettings.style';

import {
  useGetRetailerAccountCreativeSpecsQuery,
  usePutRetailerAccountCreativeSpecsMutation,
} from '#/store/api/retailerAccount/retailerAccount';

const CreativeSpecsSetting = () => {
  const classes = useGeneralSettingStyle();
  const { data, isLoading, isFetching } = useGetRetailerAccountCreativeSpecsQuery();
  const { watch, setValue } = useForm();
  const [triggerPutRetailerAccountCreativeSpecsMutation] = usePutRetailerAccountCreativeSpecsMutation();
  useEffect(() => {
    if (watch('creativeSpecs')?.length >= 0) {
      triggerPutRetailerAccountCreativeSpecsMutation({ creativeSpecs: watch('creativeSpecs', []) });
    }
  }, [watch('creativeSpecs')?.length]);
  return (
    <Card customClass={classes.sectionCard}>
      <TypographyWithFontFamily gutterBottom variant={'H5_Base'}>
        Creative Specs
      </TypographyWithFontFamily>
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <Box className={classes.fileContainer}>
          <MultipleDownloadUpload
            editMode
            filesIds={watch('creativeSpecs', data || [])}
            registerName="creativeSpecs"
            setValue={setValue}
            uploadLink={`/retailer-account/uploads/creative-specs`}
          />
        </Box>
      )}
    </Card>
  );
};

export default CreativeSpecsSetting;
