import { useState } from 'react';

import AddUserWizard from '#/components/addUserWizard/AddUserWizard';
import AlertError from '#/components/common/Alert/AlertError/AlertError';
import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import Loader from '#/components/common/loader/loader';
import { Table } from '#/components/common/table/tableComponent/Table';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';
import { getUserHasPermission } from '#/constants/permissions/utils';
import { PartnersTable } from '#/constants/tables/partners/partnersTable';

import { useGetCompanyUserAllFiltersQuery, useGetCompanyUserAllQuery } from '#/store/api/users/users';

const PartnerUsersRetailer = () => {
  const { data: filterData, isError: filterDataIsError } = useGetCompanyUserAllFiltersQuery({});

  const { actions, state } = useSearchFilters(undefined, true, filterData?.filters);

  const [isAddUserWizardOpen, setIsAddUserWizardOpen] = useState(false);

  const {
    data: companyUserData,
    isError: companyUserIsError,
    isFetching: companyUserIsFetching,
    isLoading: companyUserIsLoading,
  } = useGetCompanyUserAllQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  const userHasPartnersUsersActivateDeactivateUserWrite = getUserHasPermission(
    USER_PERMISSIONS.PARTNERS_USERS_ACTIVATE_DEACTIVATE_USER_WRITE,
  );

  const [error, setError] = useState();

  const { partnerUsersColumnTable } = PartnersTable(
    filterData ? filterData.filters : [],
    userHasPartnersUsersActivateDeactivateUserWrite,
    setError,
  );

  if (companyUserIsLoading) return <Loader />;

  return (
    <>
      <AlertError error={error} />
      <SearchFilters
        columnNames={filterData?.filters}
        currentIndex={companyUserData?.currentIndex}
        filterDataIsError={filterDataIsError}
        hideViewMode
        isError={companyUserIsError}
        isFetching={companyUserIsFetching}
        isNoResults={!companyUserData || companyUserData.users.length === 0}
        totalPages={companyUserData?.totalPages}
        totalResults={companyUserData?.totalResults}
        {...state}
        {...actions}
      >
        <Table
          columns={partnerUsersColumnTable}
          data={companyUserData?.users || []}
          displayStatusColor
          sortProps={{
            sortParams: state.sortParams,
            setSortParams: actions.setSortParam,
          }}
        />
      </SearchFilters>
      {isAddUserWizardOpen && <AddUserWizard onClose={() => setIsAddUserWizardOpen(false)} />}
    </>
  );
};
export default PartnerUsersRetailer;
