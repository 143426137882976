import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import {
  Filter,
  FilterOptions,
  FiltersActions,
  FiltersState,
} from '#/components/common/SearchFilters/SearchFilters.types';
import isFilterInvalid from '#/components/common/SearchFilters/isValidFilter';
import { CalendarModes, CampaignListModes } from '#/components/common/TabModeToggle';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';

const initialState = {
  pageNumber: 1,
  numberOfElementsPerPage: 50,
  sortableColumns: {
    name: true,
    status: true,
    stage: true,
    campaignGoal: true,
  },
  sortParams: {
    column: '',
    order: '',
  },
  alwaysActiveFilters: [],
  filters: [],
  searchText: '',
  searchDropdownValue: '',
  searchOperation: '',
  viewMode: CampaignListModes.LIST,
  searchQuery: '',
};

const defaultOptions = {
  viewMode: CampaignListModes.LIST,
  alwaysActiveFilters: [] as Filter[],
  filters: [] as Filter[],
};

const calculateSearchQueryBasedOnFilters = <T>(state: FiltersState<T>, filtersData: SearchFilters[] = []) => {
  const filters = [...state.alwaysActiveFilters, ...state.filters]
    .reduce((acc, filter) => {
      if (isFilterInvalid(filter, filtersData)) return [...acc];

      const filterString = `${filter.key}${filter.operation !== '' ? filter.operation : '='}'${filter.value}'`;
      return [...acc, filterString];
    }, [] as string[])
    .join(' && ');

  const sort = state.sortParams.column !== '' ? ` ORDER BY ${state.sortParams.column} ${state.sortParams.order}` : '';

  return `${filters}${sort}`;
};

/**
 * Returns a collection of state functions
 * @function
 * @param{boolean} allowSearchParams - flag that syncs the filters to the browser`s URL
 * @param{FilterOptions} options - config object for settings different options for the search filters
 */
function useSearchFilters<T extends CampaignListModes | CalendarModes>(
  options: FilterOptions<T> = defaultOptions as FilterOptions<T>,
  allowSearchParams: boolean = true,
  filterData: SearchFilters[] | undefined,
): {
  state: FiltersState<T>;
  actions: FiltersActions;
} {
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useImmer<FiltersState<T>>({
    ...initialState,
    filters: options.filters || defaultOptions.filters,
    viewMode: options.viewMode,
    alwaysActiveFilters: options.alwaysActiveFilters,
    searchQuery: calculateSearchQueryBasedOnFilters(
      {
        ...initialState,
        alwaysActiveFilters: options.alwaysActiveFilters,
      },
      filterData,
    ),
  });

  const updateURLSearchParams = <T>(draft: FiltersState<T>) => {
    if (!allowSearchParams) return;

    if (draft.filters.length === 0) {
      setSearchParams({});
    } else {
      const newSearchParams = draft.filters.reduce(
        (accumulator, filter) =>
          accumulator + `${filter.key}=${filter.value}^${filter.operation !== '' ? filter.operation : '='}&`,
        '',
      );
      setSearchParams(newSearchParams);
    }
  };

  const setSearchDropdownValue = useCallback((newSearchDropdownValue: string) => {
    setState((draft) => {
      draft.searchDropdownValue = newSearchDropdownValue;
      draft.searchText = '';
    });
  }, []);

  const setSearchText = useCallback((newSearchText: string) => {
    setState((draft) => {
      draft.searchText = newSearchText;
    });
  }, []);

  const setSearchOperation = useCallback((newSearchOperation: string) => {
    setState((draft) => {
      draft.searchOperation = newSearchOperation;
    });
  }, []);

  const clearFilters = useCallback(() => {
    setState((draft) => {
      draft.filters = [];
      draft.searchQuery = calculateSearchQueryBasedOnFilters(draft, filterData);
      draft.pageNumber = initialState.pageNumber;
      updateURLSearchParams(draft);
    });
  }, []);

  const loadFilters = useCallback(() => {
    if (!allowSearchParams) return;

    setState((draft) => {
      for (const [key, valOp] of searchParams) {
        const [value, operation] = valOp.split('^');
        const isFilterActiveAlready = draft.filters.some(
          (filter: Filter) => filter.key === key && filter.value === value && filter.operation === operation,
        );
        if (!isFilterActiveAlready) {
          draft.filters.push({ key, value, operation });
        }
      }
      draft.searchQuery = calculateSearchQueryBasedOnFilters(draft, filterData);
    });
  }, []);

  const addFilter = useCallback(() => {
    setState((draft) => {
      draft.filters.push({
        key: draft.searchDropdownValue,
        value: draft.searchText.trim(),
        operation: draft.searchOperation,
      });
      draft.searchQuery = calculateSearchQueryBasedOnFilters(draft, filterData);
      draft.searchText = initialState.searchText;
      draft.pageNumber = initialState.pageNumber;
      updateURLSearchParams(draft);
    });
  }, [filterData, state.searchDropdownValue, state.searchText, state.searchOperation]);

  const removeFilter = useCallback((filter: Filter) => {
    setState((draft) => {
      draft.filters = draft.filters.filter((el) => `${el.key}${el.value}` !== `${filter.key}${filter.value}`);
      draft.searchQuery = calculateSearchQueryBasedOnFilters(draft, filterData);
      draft.pageNumber = initialState.pageNumber;
      updateURLSearchParams(draft);
    });
  }, []);

  const setViewMode = useCallback((newViewMode) => {
    setState((draft) => {
      draft.viewMode = newViewMode;
    });
  }, []);

  const setSortParam = useCallback((newSortParams) => {
    setState((draft) => {
      draft.sortParams = newSortParams;
      draft.searchQuery = calculateSearchQueryBasedOnFilters(draft, filterData);
      draft.pageNumber = initialState.pageNumber;
    });
  }, []);

  const setPage = useCallback((newPageNumber: number) => {
    setState((draft) => {
      draft.pageNumber = newPageNumber;
    });
  }, []);

  const setNumberOfElementsPerPage = useCallback((newNumberOfElementsPerPage: number) => {
    setState((draft) => {
      draft.numberOfElementsPerPage = newNumberOfElementsPerPage;
      draft.searchQuery = calculateSearchQueryBasedOnFilters(draft, filterData);
      draft.pageNumber = initialState.pageNumber;
    });
  }, []);

  useEffect(loadFilters, []);

  return {
    actions: {
      setSearchDropdownValue,
      setSearchText,
      clearFilters,
      addFilter,
      removeFilter,
      setViewMode,
      setSortParam,
      setPage,
      setNumberOfElementsPerPage,
      loadFilters,
      setSearchOperation,
    },
    state,
  };
}

export default useSearchFilters;
